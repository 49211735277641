import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Check, X } from "react-feather";
import { useApi } from "../../../api/useApi";
import { LoadingButton } from "../../components";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";

const SubscriptionFeatures = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    line-height: 25px;

    svg {
      color: #3eaf3f;
      height: 15px;
      width: 15px;
      margin-right: 3px;
    }

    &.disabled svg {
      color: #ff6058;
    }
  }
`;

const Promo = styled.p`
  text-align: center;
`;

const Wrapper = styled.div`
  font-size: 14px;
`

export const PlanManagement = () => {
  const [activePlan, setActivePlan] = useState(1);
  const [currentPlan, setCurrentPlan] = useState(1);
  const context = useApi();
  const authContext = useAuth();
  // useEffect(() => {
  //   setCurrentPlan(authContext? ? 2 : 1);
  //   setActivePlan(authContext? ? 2 : 1);
  // }, [authContext?]);

  const handleRedirectToBillingPortal = async () => {
    try {
      const response = await context?.manageSubscription();
      window.open(response.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Row className='mb-3 mt-4'>
        <Col>
          <div
            className={"subscriptionPlan " + (activePlan === 1 && "active")}
            onClick={() => setActivePlan(1)}
          >
            <div className={"subscriptionPlanName "}>Free</div>
            USD $0/month
          </div>
        </Col>
        <Col>
          <div
            className={"subscriptionPlan " + (activePlan === 2 && "active")}
            onClick={() => setActivePlan(2)}
          >
            <div className='subscriptionPlanName'>PLUS</div>
            USD $39/month
          </div>
        </Col>
      </Row>

      {activePlan === 1 && <Promo>Get all SELF Realty services</Promo>}
      {activePlan === 2 && <Promo>Includes all SELF Realty services</Promo>}

      <Container>
        <div className='mb-4'>
          <h4>PLAN DETAILS</h4>
          <SubscriptionFeatures>
            <li>
              <Check />
              RENT-O-MATIC
            </li>

            {activePlan === 1 && (
              <li className='disabled'>
                <X />
                THE PROFORMANATOR
              </li>
            )}
            {activePlan === 1 && (
              <li className='disabled'>
                <X />
                C.R.E.A.M. search
              </li>
            )}
            {activePlan === 1 && (
              <li className='disabled'>
                <X />
                DOSSIER (3 credits/mo included)
              </li>
            )}
            {activePlan === 1 && (
              <li className='disabled'>
                <X />
                First look at new services
              </li>
            )}

            {activePlan === 2 && (
              <li>
                <Check />
                THE PROFORMANATOR
              </li>
            )}
            {activePlan === 2 && (
              <li>
                <Check />
                C.R.E.A.M. search
              </li>
            )}
            {activePlan === 2 && (
              <li>
                <Check />
                DOSSIER (3 credits/mo included)
              </li>
            )}
            {activePlan === 2 && (
              <li>
                <Check />
                First look at new services
              </li>
            )}
          </SubscriptionFeatures>
        </div>
      </Container>
      <hr />
      {activePlan === currentPlan && (
        <Button disabled className='upgradePlanBtn'>
          Current plan
        </Button>
      )}
      {activePlan > currentPlan && (
        <>
        <Button onClick={() => navigate("/dashboard/subscribe")}>
            <b>Upgrade to PLUS</b>
          </Button>
          <p className='mt-2 alignCenter'>14 day free trial</p>
        </>
      )}
      {activePlan < currentPlan && (
        <LoadingButton
          className='downgradePlanBtn'
          label={<b>Downgrade Plan</b>}
          callback={handleRedirectToBillingPortal}
        />
      )}
    </Wrapper>
  );
};
