import { useEffect, useState } from "react";
import { useApi } from "../../../api/useApi";
import { LoadingButton, LoadingIndicator } from "../../components";
import { UserPreferences } from "../../../api/interfaces/preferences";
import { PreferencesEditor } from "./components";
import {
  Form,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Image,
} from "react-bootstrap";
import { styled } from "styled-components";
import { CornerUpLeft } from "react-feather";
import { useNavigate } from "react-router-dom";

const DropdownIcon = styled(Image)`
  width: 25px;
  margin-right: 5px;
  border-radius: 8px;
`;

const ReturnBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    color: #8c8c8c;
  }
`;

const Select = styled(DropdownButton)`
  button {
    text-align: left;
    border: 1px solid var(--main-border-color);
    background-color: #1a233d;
    color: var(--content-color);
  }
`;

const portals: Record<string, any> = {
  REDFIN: {
    name: "Redfin",
    icon: "/images/dropdown_icons/redfin_150.jpeg",
  },
  REALTOR: {
    name: "Realtor",
    icon: "/images/dropdown_icons/realtor_150.png",
  },
  ZILLOW: {
    name: "ZIllow",
    icon: "/images/dropdown_icons/zillow_150.jpg",
  },
};

export const Preferences = () => {
  const apiContext = useApi();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [preferences, setPreferences] = useState<UserPreferences | null>(null);

  const saveUserPreferences = async () => {
    if (preferences) {
      await apiContext?.saveUserPreferences(preferences);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await apiContext?.getUserPreferences();
      if (data) {
        setPreferences(data);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!preferences) return null;

  let portalDropDownTitle: React.ReactNode;
  if (preferences.preferred_portal && portals[preferences.preferred_portal]) {
    portalDropDownTitle = (
      <>
        <DropdownIcon src={portals[preferences.preferred_portal].icon} />
        {portals[preferences.preferred_portal].name}
      </>
    );
  } else {
    portalDropDownTitle = (
      <>
        <DropdownIcon src={portals["REALTOR"].icon} />
        {portals["REALTOR"].name}
      </>
    );
  }

  return (
    <>
      <ReturnBtn onClick={() => navigate(-1)}>
        <CornerUpLeft />
      </ReturnBtn>
      <Form.Group className='mb-3'>
        <b>Preferred Portal</b>

        <Select title={portalDropDownTitle}>
          {Object.keys(portals).map((k) => (
            <Dropdown.Item
              key={k}
              onClick={() =>
                setPreferences({
                  ...preferences,
                  preferred_portal: k,
                })
              }
            >
              <DropdownIcon src={portals[k].icon} />
              {portals[k].name}
            </Dropdown.Item>
          ))}
        </Select>
      </Form.Group>
    
      <PreferencesEditor preferences={preferences} onChange={setPreferences} />

      <LoadingButton label='Save' callback={saveUserPreferences} />
    </>
  );
};
