import { Listing } from "../../../api/interfaces/listing";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { Dossier } from "../../../api/interfaces/dossier";
import { OrderBox } from "./components";
import { ExternalLinks } from "../../components/listing";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const OrderBoxStyled = styled.div`
  padding: 45px;
  word-wrap: break-word;
`;

const DossierImage = styled.div`
  width: 300px;
  border-radius: 8px;
  overflow-x: hidden;
`;

const DossierInfo = styled.div`
  width: 300px;
  border-radius: 8px;
  border: 2px solid #ffb41d;
  font-size: 14px;
  padding: 10px 0px;
`;

const DossierTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  padding: 0px 15px;
`;

const DossierSubTitle = styled.div`
  font-size: 18px;
  padding: 0px 15px;
`;

const DossierProps = styled.div`
  border-top: 2px dashed #ffb41d;
  padding: 10px 15px 0px;
`;

interface Props {
  dossier: Dossier | null;
  order: Dossier | null;
  listing: Listing | null;
}

export const DossierView = (props: Props) => {
  return (
    <>
      {props.order !== null && (
        <OrderBoxStyled>
          <OrderBox dossier={props.order} />
          {props.order.delivered && props.order.content && (
            <>
              <div className='mt-3'>
                <div className='mb-3'>
                  {props.listing && (
                    <ExternalLinks enableMls enableRentomatic listing={props.order?.listing} />
                  )}
                </div>

                <b>Files</b>
                <br />
                <a href={props.order.content} target='_blank' rel='noreferrer'>
                  {props.order.content}
                </a>
              </div>
            </>
          )}
        </OrderBoxStyled>
      )}
      {props.order === null && (
        <Wrapper>
          {props.listing?.media_url && (
            <DossierImage className='mb-4'>
              <Image width={300} src={props.listing?.media_url} />
            </DossierImage>
          )}
          <DossierInfo>
            <DossierTitle>{props.listing?.full_address}</DossierTitle>
            <DossierSubTitle className='mb-2'>{`${props.listing?.city}, ${props.listing?.state} ${props.listing?.postal_code}`}</DossierSubTitle>
            <DossierProps>
              <div>
                Source: {props.listing?.data_source} {props.listing?.listing_id}
              </div>
              {props.listing?.last_modified_date && (
                <div>
                  Last Changed:{" "}
                  {new Date(
                    props.listing.last_modified_date
                  ).toLocaleDateString("en-US")}
                </div>
              )}
              <div>Listed By: {props.listing?.list_agent_full_name}</div>
              <div>Brokered By: {props.listing?.list_office_name}</div>
            </DossierProps>
          </DossierInfo>
        </Wrapper>
      )}
    </>
  );
};
