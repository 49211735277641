import { useEffect, useState } from "react";
import { useApi } from "../../../../api/useApi";
import { Dossier } from "../../../../api/interfaces/dossier";
import styled from "styled-components";
import { OrderBox } from "./OrderBox";

const OrderBoxStyled = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  font-size: 10px;
  cursor: pointer;

  b {
    font-size: 12px;
  }
`;

const OrderListWrapper = styled.div`
  height: 210px;
  overflow-y: scroll;
  padding: 0px 5px;
`;

const sampleDossier = {
  "id": 60,
  "listing":  {
      "listing_key": "d5949c877a7991195c724d1527ab6885",
      "iff_unit_number": "",
      "cp_lp_ratio": 1,
      "cp_lp_diff": 1,
      "cp_sq_ft": 1,
      "full_address": "304 James Adkins DR (Sample)",
      "days_on_market": 172,
      "active": true,
      "favorite": false,
      "created_at": "2024-05-14T21:18:31.092908",
      "updated_at": "2024-11-01T00:07:58.027447",
      "enabled": true,
      "postal_code": "78640",
      "standard_status": "Active",
      "property_sub_type": "Single Family Residence",
      "close_date": null,
      "property_type": "Residential",
      "listing_id": "8533725",
      "unparsed_address": "304 James Adkins DR, Kyle TX 78640",
      "street_name": "James Adkins",
      "subdivision_name": "Creekside Village Sec 1 & 2",
      "listing_contract_date": "2024-05-14",
      "price_change_timestamp": "2024-10-23T20:03:49.003000",
      "mls_area_major": "HH",
      "street_number": "304",
      "street_suffix": "DR",
      "unit_number": null,
      "association_fee_frequency": "Monthly",
      "bedrooms_total": 4,
      "building_area_total": null,
      "living_area": 2467,
      "list_price": 363000,
      "latitude": 30.003052,
      "longitude": -97.87241,
      "year_built": 2017,
      "bathrooms_total_decimal": 2.5,
      "lot_size_area": 0.23,
      "close_price": null,
      "association_fee_raw": 35,
      "provider_estimated_taxes": 9808,
      "pool_private": false,
      "waterfront": false,
      "garage": false,
      "association": true,
      "media_url": "https://dvvjkgh94f2v6.cloudfront.net/62e30b5c/122255612/83dcefb7.jpeg",
      "elementary_school": "Kyle",
      "middle_or_junior_school": "Laura B Wallace",
      "high_school": "Jack C Hays",
      "city": "Kyle",
      "state": "TX",
      "county_or_parish": "Hays",
      "external_listing_url": null,
      "is_affordable": false,
      "association_fee": 35,
      "estimated_taxes": 9808,
      "per_square_foot": 147.142278070531,
      "per_bedroom": 90750,
      "external_url_redfin": "https://www.redfin.com/TX/Kyle/304-James-Adkins-Dr-78640/home/143937719",
      "external_url_compass": null,
      "external_url_zillow": "https://www.zillow.com/homes/243294925_zpid/",
      "external_url_realtor": "https://www.realtor.com/realestateandhomes-detail/M8339971987",
      "last_modified_date": "2024-10-23T20:07:33.682000",
      "direction_faces": "West",
      "previous_list_price": 367000,
      "original_list_price": 395000,
      "owner_name": "Jeffrey & Amy Summers",
      "elementary_school_district": "Hays CISD",
      "pets_allowed": [],
      "list_agent_photo": null,
      "price_area_ratio": 147.14,
      "purchase_contract_date": null,
      "data_source": "ACTRIS",
      "parcel_number": "48209:R149766:261481943",
      "legal_description": null,
      "mailing_address": null,
      "ownership": null,
      "location": "SRID=4326;POINT (-97.87241 30.003052)"
  },
  "created_at": "2024-10-28T16:16:34.379190",
  "updated_at": "2024-10-28T16:40:21.947233",
  "content": "https://www.dropbox.com/scl/fo/q99ux1z5vo592l8w2pcpn/AEi85N7FgWFPcjWIqZEgubo?rlkey=gegbz1mleoxgjftqzkcs3zsik&st=jrs1ubpl&dl=0",
  "listing_url": "https://www.zillow.com/homes/402298127_zpid/",
  "delivered": true,
  "order": 70
}


interface Props {
  onSelect: (order: Dossier) => void;
}

export const OrderList = (props: Props) => {
  const [orders, setOrders] = useState<Dossier[]>([sampleDossier]);
  const apiContext = useApi();

  useEffect(() => {
    apiContext?.getDossierList().then((list) => setOrders([sampleDossier, ...list]));
  }, []);

  return (
    <OrderListWrapper>
      {orders.map((order) => (
        <OrderBoxStyled
          key={order.id}
          onClick={() => props.onSelect(order)}
          className='mb-2 withBoxShadow'
        >
          <OrderBox dossier={order} />
        </OrderBoxStyled>
      ))}
    </OrderListWrapper>
  );
};
