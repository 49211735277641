import { LoadingIndicator } from "../../components";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { Bundle } from "../../../api/interfaces/modules";
import { ContentWithSideBar } from "../../components/layout";
import { Checkout } from "./Checkout";
import { Basket } from "./Basket";
import { useNavigate } from "react-router-dom";
import { useModules } from "../../hooks/useModules";

export function CheckoutWrapper() {
  const apiContext = useApi();
  const modulesContext = useModules();
  const navigate = useNavigate();
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null);

  useEffect(() => {
    const refreshBundles = () => {
      apiContext?.getModulesBundles().then((res) => {
        setBundles(res);
        setSelectedBundle(res.find((bundle: Bundle) => bundle.active) ?? null);
      });
    };
    refreshBundles()
    // const interval = setInterval(() => refreshBundles(), 5000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => modulesContext?.getUserModules(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onBundleSelect = (bundle: Bundle, selected: boolean): void => {
    if (selected) {
      setSelectedBundle(bundle);
    } else {
      setSelectedBundle(null);
    }
  };

  const manageSubscription = async () => {
    const response = await apiContext?.manageSubscription();
    window.open(response.url, "_blank");
  };

  const onCheckout = async (): Promise<void> => {
    if (selectedBundle) {
      try {
        const response = await apiContext?.manageSubscription();
        window.open(response.url, "_blank");
      } catch (error) {
        navigate("/dashboard/subscribe", {
          state: { productsIds: [selectedBundle.price_id] },
        });
      }
    }
  };

  const addCredits = async (): Promise<void> => {
    navigate("/dashboard/subscribe", {
      state: { productsIds: [process.env.REACT_APP_STRIPE_CREDITS_PRICE_ID] },
    });
  };

  if (apiContext?.isLoading) {
    return <LoadingIndicator />;
  }
  const activeBundle = bundles.find((bundle) => bundle.active) ?? null;
  return (
    <ContentWithSideBar
      title='BILLING'
      sideBarTitle='CART'
      center={
        <Checkout
          activeBundle={activeBundle}
          selectedBundle={selectedBundle}
          bundles={bundles}
          onSelect={onBundleSelect}
          addCredits={addCredits}
        />
      }
      sidebar={
        <Basket
          selectedBundle={selectedBundle}
          activeBundle={activeBundle}
          onCheckout={onCheckout}
        />
      }
    />
  );
}
