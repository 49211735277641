import React, { useEffect, useState } from "react";
import { ListingCompactView } from "../../api/interfaces/listing";
import { useApi } from "../../api/useApi";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { ListingCard } from "../components/listing";

export const Favorites = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState<ListingCompactView[]>([]);
  const context = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const data = await context?.getFavorites();
      setFavorites(data ?? []);
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {favorites.map((l) => {
        return (
          <div key={l.listing_key} className='mb-3'>
            <ListingCard listing={l} className="withBoxShadow" />
          </div>
        );
      })}
    </>
  );
};
