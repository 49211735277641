import { useState } from "react";
import { IS_WEB_VERSION } from "../utils";

export const getStorageKey = (keyName: string): Promise<string | null> => new Promise((resolve, reject) => {
  if (IS_WEB_VERSION) {
    const item = localStorage.getItem(keyName)
    resolve(item)
  } else {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };

    window.parent.postMessage({ type: "get", key: keyName }, "*", [channel.port2]);
  }
})

export const setStorageKey = (key: string, value: any): Promise<string | null> => new Promise((resolve, reject) => {
  if (IS_WEB_VERSION) {
    if (value === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, value)
    }
    resolve(null)
  } else {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "set", key, value }, "*", [channel.port2]);
  }
})

export const useStorage = (keyName: any, defaultValue: any): [any, (newValue: any) => Promise<any>] => {
  const [storedValue, setStoredValue] = useState<null | string>(defaultValue);

  const setValue = async (newValue: any): Promise<void> => {
    await setStorageKey(keyName, newValue)
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};





