import Accordion from "react-bootstrap/Accordion";
import { UserAccount } from "./UserAccount";
import { PlanManagement } from "../dashboard";

export const Profile = () => {
  return (
    <Accordion defaultActiveKey={["0", "1"]} alwaysOpen flush className='mt-2'>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>ACCOUNT</Accordion.Header>
        <Accordion.Body>
          <div style={{ width: "50%" }}>
            <UserAccount />
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey='1'>
        <Accordion.Header>BILLING</Accordion.Header>
        <Accordion.Body>
          <div style={{ width: "70%" }}>
            <PlanManagement />
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
    </Accordion>
  );
};
