import styled from "styled-components";
import { useListing } from "../hooks/useListing";
import { Link } from "react-router-dom";

const SubjectListingWrapper = styled.div`
  position: absolute;
  height: 40px;
  left: 150px;
  width: 510px;

  overflow: hidden;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 43px;
  text-transform: capitalize;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SubjectListing = () => {
  const listingContext = useListing();
  const listing = listingContext?.listing;

  if (listing === null) {
    return null;
  }

  return (
    <SubjectListingWrapper>
      <Link to={`/rentomatic/`} title='Subject Property'>
        {listing?.full_address}
        {listing?.city ? `, ${listing.city}` : ""}
        {listing?.state && listing?.postal_code
          ? `, ${listing.state} ${listing.postal_code}`
          : ""}
        {/* {" ["}
        {listing?.property_type === "Residential" ? "For Sale" : "For Rent"}
        {`: ${listing?.standard_status}`}
        {"]"} */}
      </Link>
      {listing?.standard_status && (
        <span className='fright'>{listing?.standard_status}</span>
      )}
    </SubjectListingWrapper>
  );
};
