import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const context = useApi();

  const handlePasswordReset = async (e: any) => {
    e.preventDefault();
    setError(false);
    setIsLoading(true);
    try {
      await context?.resetPassword(email);
      navigate("/accounts/reset_confirm");
    } catch (error) {
      setError(true);
    }
    setIsLoading(false);
  };
  return (
    <>
      <p>
        Enter your account’s email and we’ll send you an email to reset the
        password.
      </p>
      <Form onSubmit={handlePasswordReset}>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            isInvalid={error}
            placeholder='Enter email'
          />
          <Form.Control.Feedback type='invalid'>
            Please make sure email is correct.
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant='primary' type='submit' disabled={isLoading}>
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Loading...
            </>
          ) : (
            "Continue"
          )}
        </Button>
        <p className='mt-2 text-center'>
          Already have an account? <Link to='/accounts/login'>Login</Link>
        </p>
      </Form>
    </>
  );
};
