import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";

import { Proforma as ProformaType } from "../../../api/interfaces/proforma";
import styled from "styled-components";
import { formatEquity, formatNumericValue } from "./components/PriceInput";
import {
  NetRentalIncome,
  ProformaTable,
  NetOperatingIncome,
  FinancingCosts,
  TransactionCosts,
  Panel,
} from "./components";

import { useAssumptions, useProforma } from "./hooks";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import { findMedian, USDollar } from "../../utils";
import { ContentWithSideBar } from "../../components/layout";
import { PageTitle } from "../../components/layout/PageTitle";
import { DossierWidget, RentomaticWidget } from "../../components/widgets";
import { Link } from "react-router-dom";

const RentalIncome = styled.div`
  padding: 10px calc(var(--bs-gutter-x) * 0.5) 0;
  margin-bottom: 10px;
`;

const getPImonthly = (
  loanAmount: number,
  interestRate: number,
  termInYears: number
) => {
  interestRate = interestRate === 0 ? 0 : interestRate / 100;
  let monthlyInterestRate = interestRate === 0 ? 0 : interestRate / 12;
  let numberOfMonthlyPayments = termInYears * 12;
  return Math.round(
    (monthlyInterestRate *
      loanAmount *
      Math.pow(1 + monthlyInterestRate, numberOfMonthlyPayments)) /
    (Math.pow(1 + monthlyInterestRate, numberOfMonthlyPayments) - 1)
  );
};

interface Props {
  onSave: (proforma: ProformaType) => Promise<void>;
  onDelete: () => Promise<void>;
  onReset: () => void;
  proforma: ProformaType;
  comparables: ListingCompactView[];
  listing: Listing | null;
}

export const Proforma = (props: Props) => {
  const [piMonthly, setPIMonthly] = useState(0);
  const [rentIncome, setRentIncome] = useState<number>(0);
  const [netOperatingIncome, setNetOperatingIncome] = useState<number>(0);

  const assumptionsState = useAssumptions(props.proforma.assumptions);
  const proformaState = useProforma(props.proforma);

  const saveProforma = () => {
    const proforma: ProformaType = {
      proforma_id: props.proforma.proforma_id,
      closing_costs: proformaState.closingCosts,
      closing_date: proformaState.closingDate,
      offer_price: proformaState.offerPrice,
      down_payment: proformaState.downPayment,
      rent_estimate: proformaState.rentEstimate,
      hoa_transfer: proformaState.hoaTransfer,

      property_tax: proformaState.propertyTax,
      provider_estimated_taxes: proformaState.providerEstimatedTaxes,

      hoa_fee: proformaState.hoaFee,
      repair_and_maintenance: proformaState.repairAndMaintenance,
      property_management: proformaState.propertyManagement,
      listing_fees: proformaState.listingFees,
      insurance: proformaState.insurance,
      other: proformaState.other,

      assumptions: {
        down_payment_rate: assumptionsState.downPaymentRate,
        closing_costs_rate: assumptionsState.closingCostsRate,
        clean_and_repair: assumptionsState.cleanAndRepair,
        loan_term: assumptionsState.loanTerm,
        interest_rate: assumptionsState.insuranceRate,
        vacancy: assumptionsState.vacancy,
        insurance_rate: assumptionsState.insuranceRate,
        enable_tax_user_estimate: assumptionsState.enableTaxUserEstimate,
        user_tax_rate: assumptionsState.userTaxRate,
        repair_and_maintenance_rate: assumptionsState.repairAndMaintenanceRate,
        property_management_rate: assumptionsState.propertyManagementRate,
        lease_listing_fee: assumptionsState.leaseListingFee,
      },
    };

    props.onSave(proforma);
  };

  useEffect(() => {
    assumptionsState.setClosingCostsRate(
      proformaState.closingCosts / (proformaState.offerPrice / 100)
    );
  }, [proformaState.closingCosts, proformaState.offerPrice]);

  useEffect(() => {
    assumptionsState.setDownPaymentRate(
      proformaState.downPayment / (proformaState.offerPrice / 100)
    );
  }, [proformaState.downPayment, proformaState.offerPrice]);

  // Repair and Maintenance  update
  useEffect(() => {
    if (proformaState.repairAndMaintenance !== null) {
      assumptionsState.setRepairAndMaintenanceRate(
        proformaState.repairAndMaintenance / (proformaState.rentEstimate / 100)
      );
    }
  }, [proformaState.rentEstimate, proformaState.repairAndMaintenance]);

  // Property Management update
  useEffect(() => {
    if (proformaState.propertyManagement !== null) {
      assumptionsState.setPropertyManagementRate(
        proformaState.propertyManagement / (proformaState.rentEstimate / 100)
      );
    }
  }, [proformaState.rentEstimate, proformaState.propertyManagement]);

  useEffect(() => {
    if (proformaState.listingFees !== null) {
      assumptionsState.setLeaseListingFee(
        ((proformaState.listingFees * 12) / proformaState.rentEstimate) * 100
      );
    }
  }, [proformaState.listingFees, proformaState.rentEstimate]);

  useEffect(() => {
    if (proformaState.insurance !== null) {
      assumptionsState.setInsuranceRate(
        ((proformaState.insurance * 12) / proformaState.offerPrice) * 100
      );
    }
  }, [proformaState.insurance, proformaState.offerPrice]);

  useEffect(() => {
    if (
      assumptionsState.enableTaxUserEstimate &&
      proformaState.propertyTax !== null
    ) {
      assumptionsState.setUserTaxRate(
        ((proformaState.propertyTax * 12) / proformaState.offerPrice) * 100
      );
    }
  }, [proformaState.propertyTax, proformaState.offerPrice]);

  useEffect(() => {
    setPIMonthly(
      getPImonthly(
        proformaState.offerPrice - proformaState.downPayment,
        assumptionsState.interestRate,
        assumptionsState.loanTerm
      )
    );
  }, [
    assumptionsState.loanTerm,
    assumptionsState.interestRate,
    proformaState.offerPrice,
    proformaState.downPayment,
  ]);

  const acquisitionCosts = [
    proformaState.closingCosts,
    assumptionsState.cleanAndRepair,
    proformaState.hoaTransfer,
    -proformaState.propertyTaxProration,
  ].reduce((partialSum, a) => partialSum + a, 0);

  const yearReturn = parseFloat(
    (
      (((netOperatingIncome - piMonthly) * 12) /
        (acquisitionCosts + proformaState.downPayment)) *
      100
    ).toFixed(2)
  );

  const comparablesMedian = findMedian(
    props.comparables.map((x) => x.days_on_market)
  );

  return (
    <ContentWithSideBar
      center={
        <>
          <Container className='mt-3'>
            <Row>
              <Col>
                <TransactionCosts
                  closingDate={proformaState.closingDate}
                  setClosingDate={proformaState.setClosingDate}
                  offerPrice={proformaState.offerPrice}
                  downPayment={proformaState.downPayment}
                  propertyTax={proformaState.propertyTax ?? 0}
                  closingCosts={proformaState.closingCosts}
                  hoaTransfer={proformaState.hoaTransfer}
                  acquisitionCosts={acquisitionCosts}
                  cleanAndRepair={assumptionsState.cleanAndRepair}
                  closingCostsRate={assumptionsState.closingCostsRate}
                  setProRataTax={proformaState.setPropertyTaxProration}
                  setHoaTransfer={proformaState.setHoaTransfer}
                  setOfferPrice={proformaState.setOfferPrice}
                  setClosingCosts={proformaState.setClosingCosts}
                />
              </Col>
              <Col>
                <FinancingCosts
                  piMonthly={piMonthly}
                  loanTerm={assumptionsState.loanTerm}
                  downPaymentRate={assumptionsState.downPaymentRate}
                  setDownPayment={proformaState.setDownPayment}
                  offerPrice={proformaState.offerPrice}
                  downPayment={proformaState.downPayment}
                />
              </Col>
            </Row>
          </Container>
          <Row>
            <Col>
              <RentalIncome>
                <NetRentalIncome
                  rentEstimate={proformaState.rentEstimate}
                  vacancy={assumptionsState.vacancy}
                  predictedVacancy={comparablesMedian}
                  onChange={setRentIncome}
                  onRentChange={proformaState.setRentEstimate}
                />
              </RentalIncome>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col>
                <NetOperatingIncome
                  repairAndMaintenance={proformaState.repairAndMaintenance}
                  propertyManagement={proformaState.propertyManagement}
                  listingFees={proformaState.listingFees}
                  insurance={proformaState.insurance}
                  other={proformaState.other}
                  propertyTax={proformaState.propertyTax}
                  hoaFee={proformaState.hoaFee}
                  setRepairAndMaintenance={
                    proformaState.setRepairAndMaintenance
                  }
                  offerPrice={proformaState.offerPrice}
                  insuranceRate={assumptionsState.insuranceRate}
                  leaseListingFee={assumptionsState.leaseListingFee}
                  repairAndMaintenanceRate={
                    assumptionsState.repairAndMaintenanceRate
                  }
                  propertyManagementRate={
                    assumptionsState.propertyManagementRate
                  }
                  userTaxRate={assumptionsState.userTaxRate}
                  enableTaxUserEstimate={assumptionsState.enableTaxUserEstimate}
                  providerEstimatedTaxes={proformaState.providerEstimatedTaxes}
                  providerAssosiationFee={proformaState.providerAssosiationFee}
                  setPropertyManagement={proformaState.setPropertyManagement}
                  setListingFees={proformaState.setListingFees}
                  setInsurance={proformaState.setInsurance}
                  setOther={proformaState.setOther}
                  setPropertyTax={proformaState.setPropertyTax}
                  setHoaFee={proformaState.setHoaFee}
                  rentEstimate={proformaState.rentEstimate}
                  netIncome={rentIncome}
                  onChange={setNetOperatingIncome}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <ProformaTable>
                  <thead>
                    <tr>
                      <th className='w60'>Mortgage Payment</th>
                      <th className='w20'>
                        <span className='textOnly'>
                          {USDollar.format(piMonthly)}
                        </span>
                      </th>
                      <th className='w20'>
                        <span className='textOnly'>
                          {USDollar.format(piMonthly * 12)}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <td className='w60'>Net Income</td>
                      <td align='right' className='w20'>
                        <div className='textOnly'>
                          {formatNumericValue(netOperatingIncome - piMonthly)}
                        </div>
                      </td>

                      <td align='right' className='w20'>
                        <div className='textOnly'>
                          {formatNumericValue(
                            (netOperatingIncome - piMonthly) * 12
                          )}
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </ProformaTable>
              </Col>
            </Row>
          </Container>
        </>
      }
      sideBarTitle='Pro Forma Estimates'
      sidebar={
        <>
          <Panel value={formatEquity(yearReturn)} text='Year 1 ROI' />
          <br /><br /><br />
          <PageTitle>Next Steps</PageTitle>
          <div className='mb-3'>
            <Link to="/rentomatic/">
              <RentomaticWidget className="withBoxShadow" estimatedRent={proformaState.rentEstimate} />
            </Link>
          </div>
          <DossierWidget
            className="withBoxShadow"
            listingKey={props.listing?.listing_key}
          />
          <div className="fixedFooter alignCenter"><strong>Proformanator's return estimates may differ from estimates elsewhere on the site depending on the expense assumptions you've set.</strong><br/><br/><Link to="/accounts/preferences">Proformanator Preferences</Link></div>
        </>
      }
    />
  );
};
