import { Dossier } from "../../../../api/interfaces/dossier";

interface Props extends React.HTMLAttributes<HTMLDivElement>{
  dossier: Dossier;
}

export const OrderBox = ({ dossier }: Props) => {
  return (
    <>
      <b>{dossier.listing.full_address}</b>
      <br />
      <span>
        created: {new Date(dossier.created_at).toLocaleDateString("en-US")}
      </span>
      {dossier.delivered && (
        <span>
          {" "}
          • updated: {new Date(dossier.updated_at).toLocaleDateString("en-US")}
        </span>
      )}
    </>
  );
};
