import { Form, InputGroup, Table } from "react-bootstrap";
import { styled } from "styled-components";
import { UserPreferences } from "../../../../api/interfaces/preferences";
import { formatPercent } from "../../proformanator/components/PriceInput";

interface Props {
  preferences: UserPreferences;
  onChange: (preferences: UserPreferences) => void;
}

export const PreferencesTable = styled(Table)`
  td,
  th {
    font-size: 14px;
    border: none;
    background: none;
    padding-top: 8px;
    padding-bottom: 2px;
    padding-right: 0px;
  }

  th {
    padding: 0;
    font-weight: 600;
    text-align: right;
  }
  td {
    width: 20%;
  }
  td:first-child {
    padding-left: 10px;
    width: 80%;
  }
  th:first-child {
    text-align: left;
  }
  .form-control,
  .form-select {
    padding: 0px 7px 0px 0px;
    height: 22px;
    line-height: 22px;
    color: #333333;
    font-size: 14px;
    background: #fff;
    text-align: right;
    border: 1px solid #000;
    border-radius: 2px;
  }
  .input-group-text {
    color: #000;
    background: none;
    padding: 0;
    width: 24px;
    justify-content: center;
    line-height: 20px;
    border-radius: 2px;
    border: 1px solid #000;
    border-right-width: 2px;
  }
`;

export const PreferencesEditor = (props: Props) => {
  const { preferences, onChange } = props;
  return (
    <div className='mt-3'>
      <div>
        <b>Financing</b>
      </div>
      <PreferencesTable>
        <tbody>
          <tr>
            <td>Interest Rate</td>
            <td>
            <InputGroup>
            <InputGroup.Text>%</InputGroup.Text>
              <Form.Control
                placeholder='7'
                value={preferences?.interest_rate}
                type='number'
                step='0.01'
                className='fright'
                onChange={(e) =>
                  onChange({
                    ...preferences,
                    interest_rate: parseFloat(e.target.value),
                  })
                }
              />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Term (Years)</td>
            <td>
              <Form.Control
                placeholder='30'
                value={preferences?.loan_term}
                type='number'
                className='fright'
                onChange={(e) =>
                  onChange({
                    ...preferences,
                    loan_term: parseFloat(e.target.value),
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Down Pmt.</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={Number(
                    formatPercent(preferences.down_payment_rate)
                  ).toString()}
                  type='number'
                  min={0}
                  max={100}
                  step={0.01}
                  placeholder='35'
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      down_payment_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </PreferencesTable>

      <div className='mb-2'>
        <b>Proformanator</b>
      </div>

      <PreferencesTable>
        <thead>
          <tr>
            <th colSpan={2}>Transaction Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Closing costs</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(preferences.closing_costs_rate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      closing_costs_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Clean & Repair</td>
            <td>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  value={Number(preferences.clean_and_repair).toString()}
                  min={0}
                  type='number'
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      clean_and_repair: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </PreferencesTable>
      <PreferencesTable>
        <thead>
          <tr>
            <th colSpan={2}>Operating Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vacancy</td>
            <td>
              <Form.Control
                value={Number(formatPercent(preferences.vacancy)).toString()}
                min={0}
                type='number'
                className='fright'
                onChange={(e) =>
                  onChange({
                    ...preferences,
                    vacancy: parseFloat(e.target.value),
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Repairs & Maintanence</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(preferences.repair_and_maintenance_rate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      repair_and_maintenance_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Property Management</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(preferences.property_management_rate)}
                  type='number'
                  min={0}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      property_management_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Lease Listing</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(preferences.lease_listing_fee)}
                  type='number'
                  min={0}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      lease_listing_fee: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Insurance</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(preferences.insurance_rate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      insurance_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Property Tax Source</td>
            <td>
              <Form.Select
                value={preferences.enable_tax_user_estimate ? "user" : "mls"}
                onChange={(e) => {
                  const enableTaxUserEstimate = e.target.value === "user";
                  onChange({
                    ...preferences,
                    enable_tax_user_estimate: enableTaxUserEstimate,
                  });
                }}
              >
                <option value='mls'>MLS</option>
                <option value='user'>User</option>
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Property Tax User Estimate</td>
            <td>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  disabled={!preferences.enable_tax_user_estimate}
                  value={formatPercent(preferences.user_tax_rate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    onChange({
                      ...preferences,
                      user_tax_rate: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </PreferencesTable>
    </div>
  );
};
