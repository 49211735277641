import { styled } from "styled-components";
import { LoadingButton } from "../LoadingButton";
import { useNavigate } from "react-router-dom";
import { Lock } from "react-feather";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  enabled: boolean;
  url: string;
  title: string;
}

const Btn = styled(LoadingButton)`
  line-height: 150%;
  padding: 0px 10px;
  box-sizing: border-box !important;
  width: auto;
  font-size: 13px;
  box-sizing: initial;
  border-radius: 5px;
  background: #4286f7;
`;

const LockIcon = styled(Lock)`
  width: 20px;
  padding-right: 5px;
  margin-top: -2px;
`;

export function CTABtn({ enabled, url, style, title, className }: Props) {
  const navigate = useNavigate();

  const handleRedirect = async () => {
    if (!enabled) {
      navigate("/dashboard");
      return;
    }
    navigate(url);
  };

  return (
    <Btn
      style={style}
      className={className}
      title='Available only for paid customers'
      label={<>{!enabled && <LockIcon />}{" "}{title}</>}
      callback={handleRedirect}
    />
  );
}
