import { Image } from "react-bootstrap";
import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const HomePage = () => {
  return <Wrapper><Image width={300} src='/images/self-logo-horiz.png' /></Wrapper>
}