import { useState } from "react";
import { ProformaAssumptions } from "../../../../api/interfaces/proforma";

export const useAssumptions = (data: ProformaAssumptions) => {
    const [downPaymentRate, setDownPaymentRate] = useState<number>(data.down_payment_rate);
    const [closingCostsRate, setClosingCostsRate] = useState<number>(
      data.closing_costs_rate
    );
    const [cleanAndRepair, setCleanAndRepair] = useState<number>(
      data.clean_and_repair
    );
    const [loanTerm, setLoanTerm] = useState<number>(data.loan_term);
    const [interestRate, setInterestRate] = useState<number>(data.interest_rate);
    const [vacancy, setVacancy] = useState<number>(data.vacancy);
    const [insuranceRate, setInsuranceRate] = useState<number>(data.insurance_rate);
    const [repairAndMaintenanceRate, setRepairAndMaintenanceRate] = useState<number>(
      data.repair_and_maintenance_rate
    );
    const [propertyManagementRate, setPropertyManagementRate] = useState<number>(
      data.property_management_rate
    );
    const [leaseListingFee, setLeaseListingFee] = useState<number>(
      data.lease_listing_fee
    );
  
    const [userTaxRate, setUserTaxRate] = useState<number>(data.user_tax_rate) 
    const [enableTaxUserEstimate, setEnableTaxUserEstimate] = useState<boolean>(data.enable_tax_user_estimate) 
  
    return {
      downPaymentRate,
      setDownPaymentRate,
      closingCostsRate,
      setClosingCostsRate,
      cleanAndRepair,
      setCleanAndRepair,
      loanTerm,
      setLoanTerm,
      interestRate,
      setInterestRate,
      vacancy,
      setVacancy,
      insuranceRate,
      setInsuranceRate,
      repairAndMaintenanceRate,
      setRepairAndMaintenanceRate,
      propertyManagementRate,
      setPropertyManagementRate,
      leaseListingFee,
      setLeaseListingFee,
      userTaxRate,
      setUserTaxRate,

      enableTaxUserEstimate,
      setEnableTaxUserEstimate
    };
  }