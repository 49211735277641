import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LoadingIndicator } from "../../components";
import { Module } from "../../interfaces/module";
import { useModules } from "../../hooks/useModules";
import { useEffect } from "react";

const ModuleBoxRow = styled(Row)`
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid #8c8c8c;
  background: #fff;
`;

const ModuleImageCol = styled(Col)`
  flex: 0 0 130px;
  padding: 0px;
`;
const ModuleDescriptionCol = styled(Col)`
  padding-right: 0px;

  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  b {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
`;

const OpenModuleBtn = styled.button`
  line-height: 20px;
  height: 20px;
  box-sizing: border-box !important;
  width: 50px;
  box-sizing: initial;
  border-radius: 5px;
  background: #4286f7;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
`;

const AddModuleBtn = styled(OpenModuleBtn)`
  background: #8c8c8c;
`;

const ModuleBox = (props: Module) => {
  const navigate = useNavigate();
  const handleModuleOpen = async (selectedModule: Module) => {
    if (!selectedModule.added) {
      navigate("/dashboard");
      return;
    }
    navigate(selectedModule.url);
  };
  return (
    <Container key={props.moduleId} className='mb-3'>
      <ModuleBoxRow className='withBoxShadow'>
        <ModuleImageCol>
          <Image width={125} rounded src={props.imagePath} />
        </ModuleImageCol>
        <ModuleDescriptionCol>
          <b>{props.title}</b>
          <p className='mt-2'>{props.description}</p>
          {props.added && (
            <OpenModuleBtn onClick={() => handleModuleOpen(props)}>
              OPEN
            </OpenModuleBtn>
          )}
          {!props.added && (
            <AddModuleBtn onClick={() => navigate("/dashboard/checkout")}>
              ADD
            </AddModuleBtn>
          )}
        </ModuleDescriptionCol>
      </ModuleBoxRow>
    </Container>
  );
};

export function Dashboard() {
  const modulesContext = useModules();

  useEffect(() => {
    const interval = setInterval(() => modulesContext?.getUserModules(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (modulesContext?.loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      {modulesContext?.modules.map((moduleProps, index) => (
        <ModuleBox key={moduleProps.moduleId} {...moduleProps} />
      ))}
    </>
  );
}
