import { SplashSetup, SplashAbout } from "./Splash";

export const HelpPage = () => {
  return (
    <div className="mt-3 mb-3">
      <SplashSetup />
      <SplashAbout />
    </div>
  );
};
