import styled from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<"div"> {}

const Title = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #000;
`;

export const PageTitle = (props: Props) => {
  return (
    <div className='mb-3 mt-2'>
      <Title>{props.children}</Title>
      <Line></Line>
    </div>
  );
};
