import styled from "styled-components";
import { Listing } from "../../../../api/interfaces/listing";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Carousel } from "./Carousel";
import { useListing } from "../../../hooks/useListing";
import { useRef } from "react";

interface Props {
  listing?: Listing;
  isAdmin: boolean;
  onListingDisable: (listingKey: string) => Promise<void>;
}

const ListingExpandedRow = styled.tr`
  border: none;

  & td {
    border: none;
    background: rgba(235, 235, 251, 0.5);
  }

  .item {
    text-align: center;
  }
`;

const IgnoreBtn = styled.button`
  background: none;
  border: none;
  height: 40px;
  line-height: 40px;
  font-size:13px;
`

function getLinks(listing: Listing) {
  const links = [
    {
      url: listing.external_url_redfin,
      icon: "/images/mls_id_redfin.png",
    },
    {
      url: listing.external_url_zillow,
      icon: "/images/mls_id_zillow.png",
    },
    {
      url: listing.external_url_realtor,
      icon: "/images/mls_id_realtorcom.png",
    },
  ];
  return links.filter((l) => l.url !== null);
}

export const ListingExpanded = (props: Props) => {
  const listingContext = useListing();
  const ref = useRef(null);

  const setListingContext = (listing: Listing) => {
    listingContext?.setListing(listing);
    listingContext?.setListingKey(listing.listing_key);
  };
  const { listing } = props;
  if (!listing) {
    return null;
  }
  const links = getLinks(listing);

  if (ref && ref.current !== null) {
    
    // ref.current.scrollIntoView({ block: "center", behavior: "instant" });
    // window.scroll({
    //   top: ref.current.offsetTop - 20,
    //   behavior: 'smooth'
    // })
  }

  return (
    <ListingExpandedRow ref={ref}>
      <td colSpan={8}>
        <Container fluid>
          {" "}
          <Row xs='auto' style={{ justifyContent: "center" }}>
            {props.isAdmin && (
              <Col>
                <IgnoreBtn
                  onClick={() =>
                    props.onListingDisable(props.listing?.listing_key!)
                  }
                >
                  Ignore this listing
                </IgnoreBtn>
              </Col>
            )}
            <Col>
              <Link
                to={`/rentomatic/${listing.listing_key}/`}
                onClick={() => setListingContext(listing)}
              >
                <Image
                  src='/images/module_icons/rent-o-matic.png'
                  width={40}
                  style={{
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to={`/dossier/${listing.listing_key}/`}
                onClick={() => setListingContext(listing)}
              >
                <Image
                  src='/images/module_icons/dossier.png'
                  width={40}
                  style={{
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to={`/proforma/${listing.listing_key}/`}
                onClick={() => setListingContext(listing)}
              >
                <Image
                  src='/images/module_icons/proformanator-logo.png'
                  width={40}
                  style={{
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                />
              </Link>
            </Col>
            {links.map((l) => (
              <Col key={l.url}>
                <a href={l.url!} target='_blank' rel='noreferrer'>
                  <Image
                    src={l.icon}
                    width={40}
                    style={{
                      borderRadius: "5px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                  />
                </a>
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <Carousel media={listing.media ?? []} />
            </Col>
          </Row>
        </Container>
      </td>
    </ListingExpandedRow>
  );
};
