

export const SplashAbout = () => {
  return (
    <div>
      <hr />
      <div>SELF Realty, LLC</div>
      <div>3120 Southwest Fwy. Suite 101, #460145 Houston, TX 77098</div>
      <div>support@selfrealty.com</div>
      <div>
        <a
          href='https://www.selfrealty.com/s/SELF-Realty-IABS.pdf'
          target='_blank'
        >
          Information About Brokerage Services
        </a>
      </div>
      <div>
        <a href='https://www.selfrealty.com/s/CN-1-5_0.pdf' target='_blank'>
          Consumer Protection Notice
        </a>
      </div>
      <div>
        <a href='https://www.selfrealty.com/dmca' target='_blank'>
          DMCA
        </a>
      </div>
      <div>
        <a href='https://www.selfrealty.com/privacy_policy' target='_blank'>
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export const SplashSetup = () => {
  return (
    <div>
      <h3>Quick Setup (Two Steps)</h3>
      <hr />
      <div>
        <div>
          <h5>Step 1: Pin it</h5>
        </div>

        <div>
          <div>
            <p>
              Click the Extension icon next to the url in the Chrome browser.
              Click the "pin" next to SELF Realty.
            </p>
          </div>
          <img
            src='/images/self-realty-setup-pin.png'
            className='setupImages'
          />
        </div>
      </div>

      <div>
        <div>
          <h5>Step 2: Browse to any single property listing.</h5>
        </div>

        <div>
          <div>
            <p>
              Works with all major property listing sites. Click the example
              listing link below. Once the window loads click the SELF button in
              your browser.
            </p>
          </div>
          <img
            src='/images/self-realty-setup-button.png'
            className='setupImages'
          />
        </div>
      </div>
    </div>
  );
};
