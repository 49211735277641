import * as Sentry from "@sentry/browser";
import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Menu from "./Menu";
import { Container, Image } from "react-bootstrap";
import { styled } from "styled-components";
import { Navigation } from "./layout/Navigation";
import { useAuth } from "../hooks/useAuth";

const View = styled.div`
  width: 988px;
  background: linear-gradient(146deg, #ffcf6f 73.46%, #fff 113.1%);
  border-top-left-radius: 8px;
  border-radius: 8px;
  padding: 45px 0 22px;
  font-size: 14px;
  color: #000;
  position: relative;
  padding-left: 88px;
  overflow: hidden;
  z-index: 200;
  height: calc(98vh);
  box-shadow: 0px 3.946px 10.522px 0px rgba(0, 0, 0, 0.5);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Sidebar = styled.div`
  z-index: 1100;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0px;
  width: 80px;
  background: linear-gradient(175deg, #ffcf6f 78%, #fff 160.27%);

  display: flex;
  flex-direction: column;
  align-items: center;

  .menuBtn {
    width: 58px;
    height: 58px;
    color: #c8c8ec;
    font-size: 20px;
    background-color: #383d53;
    border: none;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    border: 2px solid transparent;
    box-sizing: content-box;

    &.moduleActive {
      border: 2px solid #ffb41d;
    }

    img {
      width: 58px;
      height: 58px;
      display: block;
    }
  }
`;

const Logo = styled.div`
  height: 40px;
  width: 80px;
  text-align: center;
  border-bottom: 1px solid #000;

  img {
    width: 60px;
    padding-top: 6px;
  }
`;

const getCurrentUrl = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "requestUrl" }, "*", [channel.port2]);
  });

const destroyIframe = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "destroy" }, "*", [channel.port2]);
  });

export function LayoutView() {
  const documentRef = useRef(document);
  const auth = useAuth();
  const [currentUrl, setCurrentUrl] = useState<null | string>(null);

  function logCustomError(err: string) {
    const captureContext = {
      tags: {
        url: currentUrl,
      },
    };
    Sentry.captureMessage(`${err} for ${currentUrl}`, captureContext);
  }

  useEffect(() => {
    getCurrentUrl().then(setCurrentUrl);
  }, []);

  useEffect(() => {
    documentRef.current.addEventListener("click", (event: MouseEvent) => {
      const view = documentRef.current.getElementById("view");
      const logo = documentRef.current.getElementById("logo");
      const sidebar = documentRef.current.getElementById("sidebar");
      const isClickedInsideFrame = [
        // @ts-ignore
        view?.contains(event.target),
        // @ts-ignore
        logo?.contains(event.target),
        // @ts-ignore
        sidebar?.contains(event.target),
      ].some((v) => v === true);
      // @ts-ignore
      if (!isClickedInsideFrame && event.target?.tagName === "HTML") {
        destroyIframe();
      }
    });
  }, []);

  return (
    <>
      <View id='view'>
        <Sidebar id='sidebar'>
          <Logo id='logo'>
            <a href='https://selfrealty.com/' target='_blank' rel="noreferrer">
              <Image src='/images/self-logo-menu.png' />
            </a>
          </Logo>
          <Menu />
        </Sidebar>
        <Container>
          {auth?.userToken && <Navigation />}
          <Outlet
            context={{
              currentUrl,
              logCustomError,
            }}
          />
        </Container>
      </View>
    </>
  );
}
