import { AddComparable, RentComparables } from "../../../components/listing";
import { ListingCompactView } from "../../../../api/interfaces/listing";


interface EstimationWithComparablesProps {
  comparables: ListingCompactView[];
  loading: boolean;
  selectedListing: string | null;
  handleComparablesUpdate?: (comparablesIds: string[]) => Promise<void>;
}

export const EstimationWithComparables = (
  props: EstimationWithComparablesProps
) => {
  return (
    <div className={props.comparables.length > 0 ? "panel" : ""}>
      <AddComparable comparables={props.comparables} />
      <RentComparables
        editable
        size={2}
        selected={props.selectedListing}
        updateComparables={props.handleComparablesUpdate}
        comparables={props.comparables}
        loading={props.loading}
      />
    </div>
  );
};
