import { createRoot } from "react-dom/client";
import App from "./ui/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import * as Sentry from "@sentry/react";
import { AuthProvider } from "./ui/hooks/useAuth";
import { ApiProvider } from "./api/useApi";
import { ListingProvider } from "./ui/hooks/useListing";
import { CreamProvider } from "./ui/hooks/useCream";
import { ModulesProvider } from "./ui/hooks/useModules";
import { IS_WEB_VERSION } from "./ui/utils";

if (process.env.REACT_APP_DEBUG !== "true") {
  Sentry.init({
    dsn: "https://6bace72a505b1e7ba77476be04984c83@o4506855158513664.ingest.us.sentry.io/4506866216534016",
    tunnel: `${process.env.REACT_APP_API_URL}/tunnel/`,
  });
}


if (
  (IS_WEB_VERSION && window === window.top) ||
  (!IS_WEB_VERSION && window !== window.top)
) {
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <AuthProvider>
      <ApiProvider>
        <ModulesProvider>
          <ListingProvider>
            <CreamProvider>
              <App />
            </CreamProvider>
          </ListingProvider>
        </ModulesProvider>
      </ApiProvider>
    </AuthProvider>
  );
}
