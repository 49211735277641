import { useOutletContext } from "react-router-dom";

export const Apartments = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  logCustomError("Search page not supported");

  return (
    <p>
      You’re looking at an apartment community page, not a specific listing so
      we cannot provide analysis.
    </p>
  );
};
