import React, { useState, useEffect } from "react";

import { useApi } from "../../../api/useApi";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageSubTitle } from "../../components/layout/PageSubtitle";

export const FinishSubscription = () => {
  const apiContext = useApi();
  const [status, setStatus] = useState('checking');

  useEffect(() => {
    setTimeout(() => {
      setStatus("complete")
    }, 10000)
  }, [apiContext]);

  return (
    <>
      {status === "checking" && (
        <>
          <PageSubTitle>We're fetching your subscription status</PageSubTitle>
          <LoadingIndicator />
          &nbsp; Loading...
        </>
      )}
      {status === "complete" && <>
      <PageSubTitle>Success!</PageSubTitle>
      </>}
    </>
  );
};
