import React from "react";
import { Col, Row } from "react-bootstrap";
import { PageTitle } from "./PageTitle";

interface Props {
  center: React.ReactNode;
  sidebar: React.ReactNode;
  title?: React.ReactNode | string;
  sideBarTitle?: React.ReactNode | string;
  fullscreen?: boolean;
}

export const ContentWithSideBar = (props: Props) => {
  return (
    <Row>
      <Col className={`mainCol withBoxShadow ${props.fullscreen ? "fullscreen" : ""}`}>
        {props.title && <PageTitle>{props.title}</PageTitle>}
        {props.center}
      </Col>
      <Col className='sidebarCol'>
        {props.sideBarTitle && <PageTitle>{props.sideBarTitle}</PageTitle>}
        {props.sidebar}
      </Col>
    </Row>
  );
};
