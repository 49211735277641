import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";
import { useModules } from "../hooks/useModules";
import { useListing } from "../hooks/useListing";
import { Module } from "../interfaces/module";

const MenuLink = styled(Link)`
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 40px;
`;

const Menu = () => {
  const auth = useAuth();
  const listingContext = useListing();
  const modulesContext = useModules();
  const location = useLocation();
  const nonContextModules = ["lookup", "cream"];
  const isAuthenticated =
    auth?.userToken !== null && auth?.userToken !== undefined;

  const isEnabled = (module: Module): boolean => {
    if (!isAuthenticated) return false;
    if (!module.added) return false;
    if (nonContextModules.includes(module.moduleId)) return true;
    return listingContext?.listing !== null;
  };

  const isSelected = (url: string): boolean => {
    return location.pathname.startsWith(url);
  };

  return (
    <>
      <Link
        to={"/dashboard"}
        className={`menuBtn  ${
          isSelected("/dashboard") ? "moduleActive withBoxShadow" : ""
        }`}
        title='HQ: Home'
      >
        <Image src='/images/module_icons/hq.png' />
      </Link>
      {modulesContext?.modules.map((module) => {
        if (isEnabled(module)) {
          return (
            <Link
              key={module.moduleId}
              to={module.url}
              title={module.title}
              className={`menuBtn ${
                isSelected(module.url) ? "moduleActive withBoxShadow" : ""
              }`}
            >
              <Image src={module.imagePath} />
            </Link>
          );
        }
        return (
          <div key={module.moduleId} className='menuBtn moduleBtnDisabled'>
            <Image title={module.title} src={module.imagePath} />
          </div>
        );
      })}

      <MenuLink to='/feedback'>Feedback</MenuLink>
    </>
  );
};

export default Menu;
