import Pagination from "react-bootstrap/esm/Pagination";

interface Props {
  total: number;
  offset: number;
  limit: number;
  setOffset: (offset: number) => void;
}

const getOffset = (i: number, limit: number) => {
  return i * limit;
};

function PaginationContainer({ total, offset, limit, setOffset }: Props) {
  const current = offset / limit;
  const totalPages = Math.ceil(total / limit);

  if (totalPages === 0) return null;

  const start = Math.max(current - 4, 0);
  const end = Math.min(current + 4, totalPages - 1);

  const range = Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);

  const items = range.map((i) => {
    return (
      <Pagination.Item
        key={i}
        active={i === current}
        onClick={() => setOffset(getOffset(i, limit))}
      >
        {i + 1}
      </Pagination.Item>
    );
  });
  if (total <= limit || totalPages === 0) {
    return null;
  }

  return (
    <Pagination>
      {current > 0 && (
        <Pagination.Prev
          onClick={() => setOffset(getOffset(current - 1, limit))}
        >
          &lt; Previous
        </Pagination.Prev>
      )}
      {items}
      {current + 1 < totalPages && (
        <Pagination.Next
          onClick={() => setOffset(getOffset(current + 1, limit))}
        >
          Next &gt;
        </Pagination.Next>
      )}
    </Pagination>
  );
}

export default PaginationContainer;
