import { Button, Table } from "react-bootstrap";
import { Bundle } from "../../../api/interfaces/modules";

interface Props {
  selectedBundle: Bundle | null;
  activeBundle: Bundle | null;
  onCheckout: () => Promise<void>;
}

export function Basket({ selectedBundle, activeBundle, onCheckout }: Props) {
  const selectedBundles = selectedBundle ? [selectedBundle] : [];
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Pricing</th>
          </tr>
        </thead>
        <tbody>
          {selectedBundles.map((bundle) => {
            return (
              <tr key={bundle.id}>
                <td>{bundle.name}</td>
                <td>{bundle.pricing}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        className="btn-primary"
        variant={selectedBundle === activeBundle ? "dark" : "primary"}
        onClick={onCheckout}
      >
        Manage Plan
      </Button>
    </>
  );
}
