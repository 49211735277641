import React, { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { Listing } from "../../api/interfaces/listing";

type ContextType = {
  listing: Listing | null;
  setListing: (listing: Listing) => void;
  
  listingKey: string | null;
  setListingKey: (listingKey: any) => void;

};

const ListingContext = createContext<ContextType | null>(null);

export const ListingProvider = ({ children }: any) => {
  const [listingKey, setListingKey] = useState<string | null>(null);
  const [listing, setListing] = useState<Listing | null>(null);
  const value = useMemo(
    () => ({
      listingKey,
      setListingKey,
      listing,
      setListing
      
    }),
    [listingKey, listing]
  );
  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  );
};

export const useListing = () => {
  return useContext(ListingContext);
};
