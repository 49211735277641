import { Listing } from "../../../api/interfaces/listing";
import { SelectedProperty, SearchFilters } from "./components";
import { CreamTypes } from "../../interfaces";

interface Props {
  activeListing?: Listing;
  activeListingScore?: number | null;
  filters: CreamTypes.Filters;
  setFilters: (filters: CreamTypes.Filters) => void;
}

export const CreamSidebar = (props: Props) => {
  return (
    <>
      <SearchFilters
        onChange={(filters) => props.setFilters(filters)}
        filters={props.filters}
      />
      <SelectedProperty
        listing={props.activeListing}
        creamScore={props.activeListingScore}
      ></SelectedProperty>
    </>
  );
};
