import { Accordion, Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { USDollar } from "../../utils";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingIndicator } from "../../components";
import { MapContainer } from "../../components/listing/Map";
import {
  Estimation,
  Listing,
  ListingCompactView,
  ListingHistory,
} from "../../../api/interfaces/listing";
import { SearchTypeAhead } from "./components";
import { ContentWithSideBar } from "../../components/layout";
import {
  AgentWidget,
  ProformaWidget,
  RentomaticWidget,
} from "../../components/widgets";
import { useAuth } from "../../hooks/useAuth";
import { EstimationWithComparables } from "../rentomatic/components";
import { useListing } from "../../hooks/useListing";
import { ExternalLinks } from "../../components/listing";

interface PropertyDataProps {
  loading: boolean;
  listing: Listing | null;
  history: ListingHistory[];
  comparables: ListingCompactView[];
  setSelectedPropertyId: (propertyId: string) => void;
  handleComparablesUpdate: (comparablesIds: string[]) => Promise<void>;
}

const HistoryTable = styled(Table)`
  text-transform: uppercase;

  tr.active:hover td {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const PropertyView = (props: PropertyDataProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const address: string = location.state.address;

  const [selectedListing, setSelectedListing] = useState<string | null>(null);

  const openRentomatic = (listingKey: string) => {
    navigate(`/rentomatic/${listingKey}`);
  };

  return (
    <Wrapper>
      <div className='mt-3 mb-3'>
        <Row>
          <Col>
            <SearchTypeAhead
              onChange={props.setSelectedPropertyId}
              value={address}
            />
          </Col>
          <Col>
            {props.listing && (
              <ExternalLinks smallIcons listing={props.listing} />
            )}
          </Col>
        </Row>
      </div>

      {(props.listing === null || props.loading) && <LoadingIndicator />}
      {!props.loading && props.listing && (
        <Accordion
          defaultActiveKey={["0", "1", "2"]}
          alwaysOpen
          flush
          className='mt-2'
        >
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Listing History</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <HistoryTable>
                    <tr>
                      <th>status</th>
                      <th>date</th>
                      <th>type</th>
                      <th>source</th>
                      <th className='alignRight'>list price</th>
                    </tr>
                    {props.history.map((x) => {
                      return (
                        <tr
                          key={x.listing_key}
                          className={
                            x.standard_status === "Active" ? "active" : ""
                          }
                          onClick={() =>
                            x.standard_status === "Active" &&
                            openRentomatic(x.listing_key)
                          }
                        >
                          <td>{x.standard_status}</td>
                          <td>
                            {new Date(
                              x.listing_contract_date
                            ).toLocaleDateString("en-US")}
                          </td>
                          <td>
                            {x.property_type === "Residential"
                              ? "Sale"
                              : "Lease"}
                          </td>
                          <td>{x.list_office_name}</td>
                          <td className='alignRight'>
                            {USDollar.format(x.list_price)}
                          </td>
                        </tr>
                      );
                    })}
                  </HistoryTable>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Property Details</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={3}>
                  <div>
                    <b>COMMUNITY</b>
                  </div>
                  <div>{props.listing.subdivision_name}</div>
                </Col>
                <Col xs={2}>
                  <div>
                    <b>TYPE</b>
                  </div>
                  <div>{props.listing.property_type}</div>
                </Col>
                <Col xs={1}>
                  <div>
                    <b>BEDS</b>
                  </div>
                  <div>{props.listing.bedrooms_total}</div>
                </Col>
                <Col xs={1}>
                  <div>
                    <b>BATHS</b>
                  </div>
                  <div>{props.listing.bathrooms_total_decimal}</div>
                </Col>
                <Col xs={1} className='alignRight'>
                  <div>
                    <b>YR.BLT</b>
                  </div>
                  <div>{props.listing.year_built}</div>
                </Col>
                <Col xs={2} className='alignRight'>
                  <div>
                    <b>SQFT</b>
                  </div>
                  <div>{props.listing.living_area}</div>
                </Col>
                <Col xs={2} className='alignRight'>
                  <div>
                    <b>EST. PRICE</b>
                  </div>
                  <div>
                    {props.listing.list_price !== null &&
                      USDollar.format(props.listing.list_price)}
                  </div>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col xs={4}>
                  <div>
                    <b>LEGAL DESCRIPTION</b>
                  </div>
                  <div>{props.listing.legal_description}</div>
                </Col>
                <Col xs={4}>
                  <div>
                    <b>OWNERSHIP</b>
                  </div>
                  <div>
                    {props.listing.ownership &&
                      props.listing.ownership.split(",").map((x) => {
                        return <div>{x}</div>;
                      })}
                  </div>
                </Col>
                <Col xs={4} className='alignRight'>
                  <div>
                    <b>MAILING ADDRESS</b>
                  </div>
                  <div>{props.listing.mailing_address}</div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Comparables</Accordion.Header>
            <Accordion.Body>
              <div className='mb-3'>
                <MapContainer
                  selectedMarker={selectedListing}
                  onMarkerSelect={(listingKey) =>
                    setSelectedListing(listingKey)
                  }
                  comparables={props.comparables}
                  startingLat={props.listing.latitude}
                  startingLng={props.listing.longitude}
                  startingKey={"custom"}
                />
              </div>
              <EstimationWithComparables
                comparables={props.comparables}
                loading={props.loading}
                selectedListing={selectedListing}
                handleComparablesUpdate={props.handleComparablesUpdate}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 12px;
`;

interface SidebarProps {
  loading: boolean;
  estimation?: Estimation | null;
}

const PropertyViewSidebar = (props: SidebarProps) => {
  if (props.loading) return null;
  return (
    <>
      <RentomaticWidget
        className='withBoxShadow'
        estimatedRent={props.estimation?.rent_prediction}
        listingUrl={"/rentomatic/"}
      />

      <ProformaWidget
        className='withBoxShadow mt-3'
        estimatedReturn={props.estimation?.return_prediction}
        listingUrl={"/proforma/"}
      />

      <AgentWidget className='withBoxShadow mt-3' />
    </>
  );
};

export const PropertyViewWrapper = () => {
  const authContext = useAuth();
  const apiContext = useApi();
  const listingContext = useListing();

  const [listingHistory, setListingHistory] = useState<ListingHistory[]>([]);
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [estimation, setEstimation] = useState<Estimation | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const { propertyId } = useParams();
  const [selectedPropertyId, setSelectedPropertyId] = useState<
    string | undefined
  >(propertyId);

  const handleLoadComparables = async (
    listing: Listing,
    comparables_ids: string[] = []
  ): Promise<void> => {
    const data = await apiContext?.getRentomatic(listing, comparables_ids);
    if (data) {
      setComparables(data.comparables);
      setEstimation(data.estimation);
    }
  };

  const handleComparablesUpdate = async (
    comparablesIds: string[]
  ): Promise<void> => {
    setLoading(true);
    try {
      await handleLoadComparables(listingContext?.listing!, comparablesIds);
    } catch (error) {
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (propertyId: string) => {
    setLoading(true);

    const response = await apiContext?.getPropertyMetadata(propertyId);

    if (response) {
      let list_price = response.listing.list_price;
      if (!list_price || list_price === 0) {
        try {
          const estimationData =
            await apiContext?.getPropertyEstimationMetadata(propertyId);
          list_price = estimationData["summary"]["estimatedValue"];
        } catch {}
      }

      const { listing, history } = response;
      const data: Listing = { ...listing, list_price };
      await handleLoadComparables(data);
      listingContext?.setListing(data);
      listingContext?.setListingKey(data.listing_key);
      setListingHistory(history);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selectedPropertyId) {
      fetchData(selectedPropertyId!);
    }
  }, [selectedPropertyId]);

  return (
    <ContentWithSideBar
      center={
        <PropertyView
          comparables={comparables}
          loading={loading}
          history={listingHistory}
          listing={listingContext?.listing ?? null}
          setSelectedPropertyId={setSelectedPropertyId}
          handleComparablesUpdate={handleComparablesUpdate}
        />
      }
      sideBarTitle='Services'
      sidebar={
        <PropertyViewSidebar
          estimation={estimation}
          loading={loading}
        />
      }
    />
  );
};
