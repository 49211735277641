import { Button, Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { CTABtn } from "./CTABtn";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  color: #000;
`;

const ImageCol = styled(Col)`
  flex: 0 0 85px;
`;

const BtnCol = styled(Col)`
  align-content: space-evenly;

  .btn-primary {
    line-height: 150%;
    padding: 0px 10px;
    box-sizing: border-box !important;
    width: auto;
    font-size: 13px;
    box-sizing: initial;
    border-radius: 5px;
    background: #4286f7;
  }
`;

const EstReturn = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> { }

export const AgentWidget = (props: Props) => {
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image
            rounded
            src='/images/module_icons/agent-on-demand.jpeg'
            width={85}
          />
        </ImageCol>
        <BtnCol>
          <EstReturn className='mb-2'>
            Book 60 minutes with a Professional
          </EstReturn>
          <Button
            onClick={() =>
              window.open(
                "https://www.selfrealty.com/services/agent-on-demand",
                "_blank"
              )
            }
          >
            SCHEDULE NOW
          </Button>
        </BtnCol>
      </Row>
    </Wrapper>
  );
};
