import React, { useEffect } from "react";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import {
  Button,
  Form,
} from "react-bootstrap";
import { User } from "../../../api/interfaces/user";
import { LoadingIndicator } from "../../components/LoadingIndicator";


export const UserAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const context = useApi();
  useEffect(() => {
    const fetchData = async () => {
      const data = await context?.getUserData();
      if (data) {
        setUser(data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleUserUpdate = async (e: any) => {
    e.preventDefault();
    setProfileUpdated(false);
    setIsLoading(true);
    if (user !== null) {
      try {
        await context?.saveUserData(user);
      } catch (error) {
        alert("Error saving user details");
      }
    }
    setIsLoading(false);
    setProfileUpdated(true);
  };

  if (user === null) {
    return <LoadingIndicator />;
  }


  return (
    <Form onSubmit={handleUserUpdate}>
      <Form.Group className='mb-3'>
        <Form.Label>First Name</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              first_name: e.target.value,
            })
          }
          type='text'
          value={user.first_name}
          placeholder='Enter first name'
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              last_name: e.target.value,
            })
          }
          type='text'
          value={user.last_name}
          placeholder='Enter last name'
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='formBasicEmail'>
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              email: e.target.value,
            })
          }
          type='email'
          value={user.email}
          placeholder='Enter email'
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Label>Password</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              password: e.target.value,
            })
          }
          type='password'
          placeholder='Password'
        />
      </Form.Group>
      <div style={{ textAlign: "right" }}>
        <Button variant='primary' type='submit' disabled={isLoading}>
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Saving...
            </>
          ) : (
            "Save"
          )}
        </Button>
      </div>
      {profileUpdated && (
        <div className='mt-3'>
          Information updated
        </div>
      )}
    </Form>
  );
};
