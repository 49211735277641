import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export function RegisterConfirmation() {
  return (
    <>
      <Link to='/accounts/login'>
        <Button>Login</Button>
      </Link>
    </>
  );
}
