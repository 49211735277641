import { useEffect } from "react";
import { useApi } from "../../api/useApi";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import { ListingCompactView } from "../../api/interfaces/listing";
import { useListing } from "../hooks/useListing";

interface PageContext {
  mls: string | null;
  zipcode: string | null;
  listingContext: string | null;
  isApartment: boolean;
  isListing: boolean;
  isConstruction: boolean;
  isSearchPage: boolean;
  isRental: boolean;
}

const requestContext = (): Promise<PageContext> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "context" }, "*", [channel.port2]);
  });

export const SearchPage = () => {
  const navigate = useNavigate();
  const apiContext = useApi();
  const listingContext = useListing();

  const getInitialListing = async (
    mls: string,
    zipcode: string
  ): Promise<ListingCompactView> => {
    const data = await apiContext?.getListings(
      {
        zipcode: zipcode,
        mls_numbers: [mls],
      },
      0,
      null,
      1
    );
    if (data.results.length === 0) {
      throw new Error("Not found");
    }
    return data.results[0];
  };

  const redirectToListing = (
    mls: string,
    zipcode: string,
    isRental: boolean,
    context: string | null
  ) => {
    getInitialListing(mls, zipcode)
      .then((listing) => {
        listingContext?.setListingKey(listing.listing_key);
        navigate(`/rentomatic/${listing.listing_key}`);
      })
      .catch(() => {
        getListingFromContext(context!, isRental);
      });
  };

  const getListingFromContext = async (context: string, isRental: boolean) => {
    const result = await apiContext?.getListingInformation(context);
    if (result) {
      listingContext?.setListing(result);
      navigate("/rentomatic/");
    }
  };

  useEffect(() => {
    requestContext().then((context) => {
      console.log(context);
      if (context.isApartment) {
        navigate("/not_found/apartments");
      } else if (context.isSearchPage) {
        navigate("/not_found/search");
      } else if (context.isRental || context.isListing) {
        if (context.mls && context.zipcode) {
          redirectToListing(
            context.mls,
            context.zipcode,
            context.isRental,
            context.listingContext
          );
        } else if (context.listingContext) {
          getListingFromContext(context.listingContext, context.isRental);
        } else {
          navigate("/not_found");
        }
      } else if (context.isConstruction && context.listingContext) {
        getListingFromContext(context.listingContext, false);
      } else {
        navigate("/not_found");
      }
    });
  }, []);

  return <LoadingIndicator />;
};
