import { useLocation, useOutletContext } from "react-router-dom";

export const PropertyNotSupported = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  const location = useLocation();
  const state = location.state;
  if (state) {
    logCustomError(`Property type ${state?.propertyType} not supported`);
  }
  return (
    <p>This property type {state?.propertyType} is not currently supported.</p>
  );
};
