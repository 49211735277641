import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { ListingCard, RentEstimations } from "../../components/listing";
import { MapContainer } from "../../components/listing/Map";
import {
  ListingCompactView,
  Estimation,
  Listing,
} from "../../../api/interfaces/listing";
import { styled } from "styled-components";
import { EstimationWithComparables } from "./components";

const ListingInfo = styled.div`
  margin: 20px 0px;
  color: #625f57;
`;

const ListingInfoCol = styled(Col)`
  border-right: 1px solid #dcdcea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 14px;

  &.no-border {
    border: none;
  }
`;

const ListingInfoTitle = styled.div`
  color: #8c8c8c;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 12px;
`;

const ComparablesWrapper = styled.div`
  left: 12px;
  right: 12px;
  bottom: 12px;
`;

interface Props {
  comparables: ListingCompactView[];
  estimation: Estimation | null;
  listing: Listing;
  loading: boolean;
  handleComparablesUpdate: (comparablesIds: string[]) => Promise<void>;
}

export const Rentomatic = (props: Props) => {
  const [selectedListing, setSelectedListing] = useState<string | null>(null);

  return (
    <div className='mt-3'>
      <div className='mb-3'>
        <ListingCard listing={props.listing} />
        <ListingInfo>
          <Container>
            <Row>
              <ListingInfoCol>
                <ListingInfoTitle>subdivision</ListingInfoTitle>
                {props.listing.subdivision_name ?? "N/A"}
              </ListingInfoCol>
              <ListingInfoCol>
                <ListingInfoTitle>days on market</ListingInfoTitle>
                {Number.isNaN(props.listing.days_on_market)
                  ? "N/A"
                  : props.listing.days_on_market}
              </ListingInfoCol>
              <ListingInfoCol className='no-border'>
                <ListingInfoTitle>original list price</ListingInfoTitle>
                {props.listing.original_list_price
                  ? `$${props.listing.original_list_price?.toLocaleString()}`
                  : "N/A"}
              </ListingInfoCol>
            </Row>
          </Container>
        </ListingInfo>
      </div>
      <div className='mb-3'>
        <MapContainer
          selectedMarker={selectedListing}
          onMarkerSelect={(listingKey) => setSelectedListing(listingKey)}
          comparables={props.comparables}
          startingLat={props.listing.latitude}
          startingLng={props.listing.longitude}
          startingKey={props.listing.listing_key}
        />
      </div>
      <Container className='mb-3'>
        {props.estimation && (
          <RentEstimations
            withEvaluations
            estimation={props.estimation}
            comparables={props.comparables}
          />
        )}
      </Container>
      <ComparablesWrapper>
        <EstimationWithComparables
          {...props}
          selectedListing={selectedListing}
        />
      </ComparablesWrapper>
    </div>
  );
};
