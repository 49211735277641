import { Col, Row, Image } from "react-bootstrap";
import { Listing } from "../../../api/interfaces/listing";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
  listing?: Listing;
  enableMls?: boolean;
  enableRentomatic?: boolean;
  smallIcons?: boolean
}

const RoundedImage = styled(Image)`
  border-radius: 5px;
`;

export function ExternalLinks(props: Props) {
  if (!props.listing) return null;
  const listing = props.listing;
  const links = [
    {
      url: listing.external_url_redfin,
      icon: "/images/mls_id_redfin.png",
    },
    {
      url: listing.external_url_zillow,
      icon: "/images/mls_id_zillow.png",
    },
    {
      url: listing.external_url_realtor,
      icon: "/images/mls_id_realtorcom.png",
    },
  ];
  if (props.enableMls) {
    links.push({
      url: listing.external_listing_url,
      icon: "/images/external_listing.png",
    });
  }
  if (links.every((x) => x.url === null)) {
    return null;
  }
  return (
    <>
      <div className='externalLinks'>
        <Row xs='auto'>
          {props.enableRentomatic && <Col>
            <Link to={`/rentomatic/${props.listing.listing_key}`}><RoundedImage src="/images/module_icons/rent-o-matic.png" width={props.smallIcons ? 30 : 45} /></Link>
          </Col>}
          {links
            .filter((l) => l.url !== null)
            .map((l) => (
              <Col key={l.url}>
                <a href={l.url!} target='_blank' rel='noreferrer'>
                  <RoundedImage src={l.icon} width={props.smallIcons ? 30 : 45} />
                </a>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
}
